.success{
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0px;
    height: 100%;
}

@media (max-width: 477px) {
    .success{
        margin: 230px 0px;
    }
}
.success h2{
    font-size: x-large;
}
